//  ------------------------------------------------------
//  ------------------------------------------------------
//  GROUPS
//  ------------------------------------------------------
//  ------------------------------------------------------

//  blog/list
 
    #page-content {
        border-left: 0px !important;
        border-right: 0px !important;
        /* margin:28px 0 0 0; */
        margin:0;
        width:100%;
        color:#b8b7b7;
        background-color:#000 !important;
        display:block; }

    // GROUP STYLES

    .group {
        width:100% !important;
        padding-top:0;
        border-left: 0px !important;
        color:#b8b7b7; }

    .group-row { clear:both; }

    .group-row footer { width:100%; }

    .row-title {
        margin:0;
        padding: 12px 0 0 0;
        padding-right: 44px !important;
        padding-left: 21px !important;
        min-height:57px;
        overflow:auto;
        text-align: left;
        background-color:#363e45;
        h1 {
            padding: 0 24px 3px 0;
            font-size:24px;
            font-weight:400;
            font-family: 'Montserrat', sans-serif;
            color:#f5f1f1;
        }
    }

    .row-html {
        padding:14px;
        padding-top:24px;
        padding-bottom:12px;
        padding-left: 47px;
        padding-right: 47px;
        overflow: hidden;
        font-size:17px;
        font-weight:500 !important;
        font-family: 'Montserrat', sans-serif !important; }

    .redline { border-top: 1px solid rgba(255, 0, 0, 0.3); }

    .row-head-odd {
        /*margin:0;
        padding-top: 5px;
        height:10px;
        background-color:#232526; */ }

    .row-head-even {
        margin:0;
        height:14px;
        border-top: 4px solid #000;
        background-color:#232526; }

    .row-subh  {
        margin:0;
        padding: 12px 0 0 0;
        padding-right: 44px !important;
        padding-left: 20px !important;
        min-height:57px;
        overflow:auto;
        /*color:#f5f1f1; b5b5b5 */
        /* color:red !important; */
        color: #869fb4;
        text-align: left;
        background-color:#1a1e21; /*1a1e21 171717*/
        /*border-top: 7px solid #363e45;*/
        border-left: 4px solid #869fb4;
        h2 {
            padding-bottom: 3px;
            font-size:24px;
            font-weight:100;
            font-family: 'Montserrat', sans-serif;
            font-style: italic;
        }
    }

    .odd { background-color:#191a1b !important; }

    .even { background-color:#232526 !important; }

    .row-item-image {
        padding:14px 0 14px 14px;
        padding-left: 24px !important;
        @include breakpoint(medium) {
            padding-left: 42px !important;
        }
        border-top: 1px solid rgba(255, 0, 0, 0.3);
        /*border-bottom: 1px solid rgba(255, 0, 0, 0.3); /*363e45*/
        /*overflow: auto;*/
        overflow:hidden !important; }

    .anchor_offset {
        display: block;
        position: relative;
        top: 0px;
        visibility: hidden; }

    .it-title {
        color:#ebedf0;
        padding:0 14px 0 0 !important;
        margin-bottom: 7px;
        h3 {
            font-size:24px;
            font-weight:300;
            font-family: 'Montserrat', sans-serif;
            padding:0 !important;
            margin:0 !important;
        }
    }

    .row-item-image .it-title {
        margin-bottom: 0;
    }

    .it-img { margin-left:0 !important; padding: 7px 21px 7px 0; }

    .it-img img { /*border-left: 5px solid #363e45; border-right: 5px solid #363e45;*/ }

    .t-desc-wrap { padding-right: 10px; }

    .row-item-image .it-desc { margin-top: 10px; padding:0 !important; }

    .desc-wrap { margin: 0; padding: 0; padding-right: 10px; }

    .it-desc { margin: 0 0 7px 0; padding:0 !important; }

    .it-desc p { margin: 0px 21px 0px 0px; }

    .it-desc .tight { margin-bottom: -7px; }


    //.it-desc { margin: 0 0 7px -10px; padding:0 !important; }




    .incl { color:#8c9399; font-style: italic; overflow: visible; } // 758999 8c9399

    .expand { height:100%; overflow:visible; /*overflow:hidden;*/ }

    .collapse { height: auto; }

    .it-rate {
        font-size:24px;
        font-weight:300;
        font-family: 'Montserrat', sans-serif;
        color:#fff;
        margin-left: -4px;
        padding: 0px 14px 7px 0px; }

    .row-item-image .it-rate { color:red; }

    .mobile-call { color:red; }
    .call-4-rate { margin-top: 7px; font-size: 17px; }

    .it-detail {
        position:relative;
        overflow: visible;
        padding: 7px 0 7px 0;
        z-index: 2; }

    .it-detail-wrap { height:153px; z-index: 2; }

    .get-detail {
        height: 35px;
        margin-left: -20px;
        position:absolute;
        bottom:14px;
        cursor: pointer;
    }

    .odd .get-detail { border-left: 7px solid #232526; }

    .odd .get-detail:hover { background: #000; text-decoration: none; }

    .even .get-detail { border-left: 7px solid #191a1b; }

    .even .get-detail:hover { background: #000; text-decoration: none; }

    .open-detail { padding-bottom: 14px; }

    .loading {
        padding: 5px 21px 0 12px;
        display:none;
        color:red;
        text-align:left;
        overflow:visible !important;
        height:0px !important; }

    .get-text {
        text-align: center;
        line-height: 37px;
        white-space: nowrap;
        overflow: hidden;
        color:#b5b6b6 !important;
        font-size:20px;
        font-weight:300;
        font-family: 'Montserrat', sans-serif;
        padding: 0 23px 0 12px;
        h4 {
            padding-top: 4px;
            font-size:20px;
        }
    }

    .close-detail {}

    .dc { width: 102%; padding-bottom:21px; overflow:hidden !important;  /* THIS CRASHES CHROME MAKES DIV DISAPPEAR*/ }

    .row-item {
        padding:14px 0 14px 14px;
        padding-left: 24px !important;
        @include breakpoint(medium) {
            padding-left: 42px !important;
        }
        // padding-left: 35px !important;
        //padding-top:21px;
        //padding-bottom:16px;
        //padding-left: 35px;
        overflow: hidden; }



    .it-detail-wrap-sm { height:35px; z-index: 2; }

    .row-foot-odd {
        margin:0;
        height:21px;
        background-color:#191a1b;
        border-bottom: 14px solid #363e45; }

    .row-foot-even {
        margin:0;
        height:21px;
        background-color:#232526;
        border-bottom: 14px solid #363e45; }

    .row-sp { height:53px; }

    // RG GROUP STYLES

    .rg-group {
        width:100% !important;
        padding-top:0;
        border-left: 0px !important;
        color:#b8b7b7; }

    .rg-loading {
        display: none;
        overflow: hidden;
        position: relative;
        height:553px;
        text-align: center;
        color:#1998ec;
        background-color: #232526; }

    .rg-loading .txt { 
        margin-top: 235px;
        font-size: 19px;
        // font-size: 21px;
    }

    .gear-title {}

    .info-title {
        padding: 0 !important;
        margin: -14px 0 0 0 !important;
        font-size:24px;
        font-weight:600 !important;
        font-family: 'Montserrat', sans-serif !important;
    }

    #gear-up { padding-top: 14px; }

    #gear-up .column { padding-bottom: 35px; }

    .gm-style-wi {
        background-color: #191a1b;
        width: 350px !important;
        top: 0 !important;
        left: 0 !important;
        box-shadow: 0 1px 6px rgba(178, 178, 178, 0.6);
        border: 1px solid rgba(72, 181, 233, 0.6);
        border-radius: 2px 2px 0 0; }

    #infow-content { }

    #firstHeading- { color: #a4a3a4; }

    /* GOOGLE MAP: */
    /* Always set the map height explicitly to define the size of the div
    * element that contains the map. */
    #contact-map p { margin-left: 0; }

    #map {
        display: block;
        height: 353px;
        width:90%;
        margin: 0 auto;
        background-color: #191a1b; }

    .download-print {
        h3, h4, h5, h6 {
            padding: 0;
            margin: 0;
            font-size: 17px;
        }
    }
    .download-print .head-indent { padding-left:14px; }
    .download-print .indent { padding-left:35px; }
    .download-print .tdownload { width: 101px; }
    .download-print .tprint { width: 101px; }

    .download-print {
        table {
            border: 0px solid #191a1b;
        }
        tbody {
            border: 0px solid #191a1b;
        }
        th, td {
            text-align: center;
        }
        th {
            padding-top: 17px;
        }
        th:first-of-type, td:first-of-type {
            text-align: left;
        }
        tr {
            border: 0px solid #191a1b;
            padding-left:21px;
        }
        tr:first-of-type {
            border-bottom: 4px solid #000;
        }
        tr:last-of-type {
            border-bottom: 7px solid #000;
        }
    }

    .even .download-print {
        tr {
            background-color: #191a1b;
        }
    }

    .even .download-print tr:nth-of-type(even) {
        background-color: #232526 !important;
    }

    .odd .download-print {
        tr {
            background-color: #232526;
        }
    }

    .odd .download-print tr:nth-of-type(even) {
        background-color: #191a1b !important;
    }

    .even .table-print {
        border-left: 2px solid #232526;
        padding: 0;
        cursor:pointer;
        color:#1460a2 !important;
        text-decoration:underline;
        height:100%;
    }
    .even .table-print:hover { color:#C0C0C0 !important; }
    .even .table-print:active { border-left: 3px solid red; margin-left: 14px; color:#fff !important; }

    .odd .table-print {
        border-left: 2px solid #191a1b;
        padding: 0;
        cursor:pointer;
        color:#1460a2 !important;
        text-decoration:underline;
        height:100%;
    }

    .odd .table-print:hover { color:#C0C0C0 !important; }
    .odd .table-print:active { border-left: 3px solid red; margin-left: 14px; color:#fff !important; }