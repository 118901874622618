//  ------------------------------------------------------
//  ------------------------------------------------------
//  PRINT MGR
//  ------------------------------------------------------
//  ------------------------------------------------------

	#print-wrap {
		padding:21px;
		min-height: 753px !important;
	}
	#head-nav { border-top: 0px solid #525d66; padding:14px; padding-top: 21px; background-color: #141414; }
	#head-nav h1 { font-size: 35px; color: #ff0000; }
	#print-wrap h2 { font-size: 30px; color: grey; }
	#print-wrap h3 { font-size: 22px; color: #869fb4; }
	#print-wrap h4 { font-size: 19px; color: #525d66; }
	.top-hr { width:570px; }
	dd { padding-left: 21px; }

	// Build PDF
	.build-wrap {
		border: solid #869fb4; 
		border-width: thin 0em 0em 0em; 
		padding: 10px;
		margin-bottom: 7px;
	}

	.build-wrap .cell {
		padding: 5px 0px;
		font-size: 14px;
		@media only screen and (min-width:32.44em) {
			font-size: 17px;
		}
	}

	// .build-bar {
	// }

	.bar-wrap {
		display:none;
		width: 100%;
		padding-top:6px;
		height:21px;
		overflow:visible;
	}

	.bar {
		width: 50%;
		background-color: #ddd;
	}

	.progress {
		width: 100%;
	}

	.myBar {
		display:none;
		width: 0%;
		font-size: 11px;
		text-align:right;
		margin-top: -2px;
		padding-right: 7px;
		// width: 1%;
		// height: 10px;
		// background-color: #4CAF50;
	}

	.build-pdf {
		// margin-top: 14px;
		cursor: pointer;
		color: #fff !important;
	}