//  ------------------------------------------------------
//  ------------------------------------------------------
//  SEARCH
//  ------------------------------------------------------
//  ------------------------------------------------------

    #search-result { display:none; width:100%; }
    #search-result-solo { width:100%; }

    #search-wrap {
        border: 1px solid #232526;
        border-left: 0px !important;
        border-right: 0px !important;
        background-color: #101010;
        #dept-header-search {
            display: none;
            /*border-right: 5px solid #232526;*/
            border-left: 0px !important;
            color:#c3c5c9;
            padding:15px 0 7px 10px;
            margin-left: 0;
            font-weight:100;
            font-family: 'Montserrat', sans-serif;
            h2 {
                font-size:24px;
            }
        }
    }

    .search-close {
        font-size:18px;
        font-weight:400;
        font-family: 'Montserrat', sans-serif;
        color: red;
        margin-left:21px;
        overflow: visible; }

    #sc-txt {
        margin-top:21px;
        margin-left:-10px;
        cursor: pointer;
        overflow:visible; }

    #sc-txt-small {
        margin-top:21px;
        margin-left:0px;
        cursor: pointer;
        overflow:visible; }

    #loadSearch {
        width:95%;
        height:553px;
        height:100%;
        width:97%;
        overflow:hidden;
        min-height:753px;
        @media only screen and (min-width:60.00em) {  
            min-height:700px;
        }
        /*margin-left: 26px;*/ }

    #loadSearch a { border-bottom: 7px; }

    // Search Loaded
    .d-search {
        color:#fff;
        /* background-color:#232526; */
        padding: 37px 37px 37px 37px;
        h4 {
            font-size: 17px;
            padding:4px 0;
            margin:0;
        }
    }

    .searched-for-results {
        font-size: 19px;
        .searched-for {
            color:#fff;
            font-weight: bold;
        }
    }

    /* .info-child { padding: 3px 0 10px 45px; } */

    .sitem-img {
        float:right;
        overflow:hidden;
        height:27px;
        margin-right:14px; }

    .stitle { padding: 3px 0; }

    .sdesc { padding-left:15px; }

    .get-info {
    float:right;
    font-size:11px;
    padding-top:4px;
    padding-left: 15px;
    color: red /*356691 88c0ec*/;
    cursor: pointer; }

    .get-info-invis {
    float:right;
    font-size:11px;
    padding-top:4px;
    padding-left: 15px; }

    .search-info { display:none; padding-left:21px !important; }

    .rate-wrap { height: 100%; }

    .rate-less-8 { width:65px; }

    .rate-more-8 { width:100%; }

    .rate1 { margin-left:34px; }

    .rate2 { margin-left:24px; }

    .rate3 { margin-left:16px; }

    .even .b-item {
        border-bottom: 1px solid #2a2c2d;
        border-top: 1px solid #2a2c2d;
        padding: 8px 0 8px 0;
        background-color: transparent;
        /*background-color:#232526;*/ }

    .odd .b-item {
        border-bottom: 1px solid #232526;
        border-top: 1px solid #232526;
        padding: 8px 0 8px 0;
        background-color: transparent;
        /*background-color:#191a1b;*/ }

    .info-active .b-item { background-color: #212223 !important; }

    .info-active { background-color: #212223 !important; color:red !important; }

    .entry_pages h3 { padding: 4px 0; margin: 0; font-size: 14px !important; height: 53px; }

    .paginate_blue { color: #3995d5; cursor: pointer; }

    .paginate_active { color: #fff !important; }