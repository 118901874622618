/*
    ------------------------------------------------------
    ------------------------------------------------------
    GLOBAL VARIABLES
    ------------------------------------------------------
    ------------------------------------------------------
*/

/*  Global Colors  */

$bg-color: #000;
$bg-color-strip: #101010;
$color-copyright-date: #b5b5b7;
$color-strip-menu-txt: #fff;
$color-slide-m-divider: red;
$color-slide-m-txt: #7d7d7d;
$color-slide-m-lvl1-txt: #7d7d7d;
$color-slide-m-lvl1-txt-has-child: #fff;
$color-slide-m-txt-hover: #fff;

$color-slide-m-txt-active: #fff;
$color-slide-m-txt-sub-active: red;
$color-slide-m-node-active: blue;

/*  Logos  */
$texcam-logo-sm-vertical: url('https://cdn.texcam.info/images/logos/logo-red-vertical-sm-opt.png');
$texcam-logo-lg-vertical: url('https://cdn.texcam.info/images/logos/texcam-logo-mono-bg-no-tags-adjusted.png');

/*  Headers  */

$header-bg-info: url('https://cdn.texcam.info/images/headers/hbg-info.jpg') !important;

$header-bg-film-and-digital-cameras: url('https://cdn.texcam.info/images/headers/hbg-cameras.jpg') !important;
$header-bg-grip-and-electric: url('https://cdn.texcam.info/images/headers/hbg-grip3.jpg') !important;
$header-bg-lenses: url('https://cdn.texcam.info/images/headers/hbg-lenses2.jpg') !important;
$header-bg-lights-and-lighting: url('https://cdn.texcam.info/images/headers/hbg-lights2.jpg') !important;
$header-bg-photography: url('https://cdn.texcam.info/images/headers/hbg-photography.jpg') !important;

$header-bg-grip-trucks: url('https://cdn.texcam.info/images/headers/hbg-trucks.jpg') !important;
$header-bg-insert-stage: url('https://cdn.texcam.info/images/headers/hbg-stage.jpg') !important;

$header-bg-search: url('https://cdn.texcam.info/images/headers/hbg-cameras.jpg') !important;