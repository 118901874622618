//  ------------------------------------------------------
//  ------------------------------------------------------
//  ROCKET MENU
//  ------------------------------------------------------
//  ------------------------------------------------------

    #logo-strip {
        min-height:535px;
        position:fixed;
        width:71px;
        margin-top: 17px;
        overflow: hidden;
        float:left;
        height:100%;
        background-position: 0px 0;
        background-color: $bg-color-strip;
        background-image: $texcam-logo-sm-vertical;
        background-repeat:no-repeat;
        z-index: 1000; }

    #logo-strip-inner {
        min-height:535px;
        position:fixed;
        margin-top: 16px;
        width:71px;
        float:left;
        height:100%; }

    #inc {
        overflow:visible;
        height:44px;
        padding-top:35px;
        width:253px;
        padding-bottom:253px;
        bottom:24px;
        left:0;
        position:absolute;
        color: $color-copyright-date;
        font-size:22px;
        font-weight:400;
        font-family: 'Montserrat', sans-serif;
        -ms-transform: rotate(-90deg); /* IE 9 */
        -webkit-transform: rotate(-90deg); /* Safari */
        transform: rotate(-90deg); }

    #inc div { padding-left: 26px; }