//  ------------------------------------------------------
//  ------------------------------------------------------
//  FOOTER
//  ------------------------------------------------------
//  ------------------------------------------------------

    #rate-msg {
        padding:12px 22px 14px 22px;
        font-size: 15px;
        background-color:#191a1b;
        border-top: 3px solid rgba(20, 20, 20, 1);
        color:#356691;
        font-style: italic; }
    #rate-msg span { color:red !important; }

    #footer { 
        margin: 0; height: auto; width: auto;
        background-image: url('https://cdn.texcam.info/images/elements/camera-schematic-v2.jpg') !important;
    }

    #tex-sm img {  }

    #tex-ph {
        text-align: right;
        color:#fff;
        font-size:28px;
        font-weight:400;
        font-family: 'Montserrat', sans-serif;
        margin-top:7px;
        padding-right: 24px;
    }
    #tex-ph a { color:#fff; }
    #tex-ph img { margin: -7px -47px 0 0; padding:0px; /*background-color: blue;*/ }

    .tex-face { height:96px; }

    #tex-copy-seal { padding-top: 35px; margin-bottom: 0; }

    #tex-copy { padding: 21px; overflow: visible; }

    #tex-rapp { color:#356691; font-size:22px; font-weight:300; font-family: 'Montserrat', sans-serif; }

    #tex-info-rate { color:#636872; font-size:44px; font-weight:400; font-family: 'Montserrat', sans-serif; }
    #tex-info-rate span { color:#dbdde2; }
    #tex-info-rate .print-page:active {
        color:red;
        span { color:#fff; text-decoration: underline; }
    }

    .print-page { cursor:pointer; text-decoration:none; }

    .print-icon { cursor:pointer; text-decoration:none; }

    #tex-address { color:#818895; width:100%; font-weight: 400; font-size: 21px; }

    #tex-address a { color:#818895; }

    .padd { padding: 0 14px 0 0; }

    #tex-seal { }

    #inc { font-size: 18px; margin-bottom: -17px; margin-left: -4px; }

//  MEDIA QUERIES

    /* SMALL -- */
    @media only screen and (max-width:32.44em) {
        #tex-sm img { display:none; }
        #tex-info-rate { font-size:32px; font-weight:400; }
        #tex-address { font-size: 17px; }
    }

    // SMALL W/LANDSCAPE
    /* Single Fluid Width Column 519px (Old Androids/iPhone3-3G-4-4S) */
    @media only screen and (max-width:32.44em) and (orientation: landscape) {
        #tex-sm img { display:none; }
        #inc { margin-bottom: -17px; margin-left: -7px; }
    }

    /* MEDIUM -- */
    /* Single Fixed Width Column 520px - 759px */
    @media only screen and (min-width:32.50em) and (max-width:47.44em) {
        #tex-ph img { margin-right:-53px; }
        #tex-rapp { font-size:26px; }
        #inc { margin-left: 2px; }
    }

    /* MEDIUM LARGE: iPAD -- */
    /* Two Fixed Width Columns, Medium Padding 760px - 959px (iPad) */
    @media only screen and (min-width:47.50em) and (max-width:59.94em) {
        #tex-ph { font-size:35px; }
        #tex-ph img { margin-top:-9px; }
        #tex-rapp { font-size:28px; }
        #inc { font-size: 18px; margin-bottom: -10px; margin-left: 5px; }
    }

    /* LARGE OR LARGER */
    /* Two Fixed Width Columns, Large Padding 960px (Desktop) */
    @media only screen and (min-width:60.00em) {  
        #rate-msg { font-size: 17px; }
        #tex-ph { font-size:42px; }
        #tex-ph img {margin-right: -53px; }
        #tex-rapp { font-size:34px; }
        #inc { font-size: 18px; margin-bottom: 0px; margin-left: 5px; }
    }
