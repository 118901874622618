//  ------------------------------------------------------
//  ------------------------------------------------------
//  RELATED GEAR MENU
//  ------------------------------------------------------
//  ------------------------------------------------------

//  Title & Related Gear Categories (stash/templates/layouts/rental_view.html)

    #sticky-header { overflow:visible !important; width:100% !important; }

    #head-cat { width:100% !important; overflow:visible !important; }

    // #header-cat a { color:#fff; }

    // RG Activated
    .rg-active #dept-header {
        background-color: #232526 !important; }

    .rg-active #rg-heading { color:#1998ec !important; font-size:27px !important; }

    .rg-active #header-cat-wrap {
        border-bottom: 5px solid #273744;
        background-color: #273744;
        overflow: auto; }

    .rg-active #header-cat {
        border-right: 0px solid #273744 !important;
        border-left: 0px !important;
        color:#fff; }

    .rg-active #header-cat ul .sub-active { color:#88c0ec !important; }

    .rg-active #header-cat a:hover { color:#88c0ec; }

    .rg-active #header-cat a { color:#fff !important; }

    // Department Header / Gear
    #dept-header-wrap {
        position:relative; z-index: 1;
        padding:0;
        border-left: 0px !important;
        border-right: 0px !important;
        background-color: #000;
        overflow:visible;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                supported by Chrome and Opera */
    }

    // Header
    #dept-header {
        min-height:87px;
        padding:0;
        overflow:auto;
        border: 5px solid #232526;
        border-left: 0px !important;
        div { padding:17px 21px 17px 21px; line-height: 135%; color:#c3c5c9; font-size:38px; font-weight:400; font-family: 'Montserrat', sans-serif; }
    }

    #dept-header #rg-heading { }

    #dp-heading {  }

    #rg-heading {}

    #related-gear {
        cursor: pointer;
        //display:block;
        min-height:90px;
        height:100%;
        padding:0;
        font-size:28px;
        font-weight:400;
        font-family: 'Montserrat', sans-serif;
        color:#808183;
        border-top: 5px solid #232526;
        border-bottom: 5px solid red;
        overflow:hidden;
        position:relative;
        #rg {
            margin-top:0;
            margin-left: 0;
            max-height: 0;
            width:100%;
            //overflow: visible;
        }
        #rgr { display: none; }
        #rgmore { display: none; }
        #rg-txt {
            position: absolute;
            margin-top: 21px;
            margin-left: 24px;
            margin-right: 65px;
            max-height: 0;
            white-space : nowrap;
            overflow:visible;
            h2 {
                font-size:28px; font-weight:400; font-family: 'Montserrat', sans-serif; color:#808183;
            }
        }
        #rg-txt-call {
            padding-top:14px !important;
        }
        div {
            overflow:hidden;
        }
    }

    #slide-w-stripes {
        text-align: right;
        overflow:visible;
    }

    #slide-w-stripes img {
        padding-top: 14px;
        margin-right: 5px;
    }

    #slide-w-x {
        text-align: right;
        overflow:visible;
        display:none;
    }

    #slide-w-x img { padding-top: 14px; margin-right: 5px; }

    #related-gear-call {
        cursor: pointer;
        //display:block;
        min-height:90px;
        height:100%;
        padding:0;
        font-size:28px;
        font-weight:400;
        font-family: 'Montserrat', sans-serif;
        color:#808183;
        border-top: 5px solid #232526;
        border-bottom: 5px solid #232526;
        overflow:hidden;
        position:relative;
        #rg-txt-call {
            position:absolute;
            padding:14px 0 14px 24px;
            //white-space : nowrap;
            overflow:hidden;
        }
        div {
            overflow:hidden;
        }
    }

    .callnow { font-size: 14px; color:#fff; clear:both; }
    .callnow-phone { margin: -4px 0 0 -3px; }
    .callnow-phone a { margin-top: 0px; color: #fff; }
    .callnowonly { display: none; }

    // #slide-w-x-rg { display:none; } ?? NEEDED ??

    .change-bg { background-color: #273744 !important; }

    .gl-root { font-weight: 700; }

    .gl-root- a { color:#fff !important; }

    #stripes { text-align: center; cursor: pointer; }

    // Department Categories / Print Options
    #header-cat-wrap {
        position:relative;
        z-index: -2;
        padding:0;
        border-left: 0px !important;
        border-right: 0px !important;
        border-bottom: 5px solid #232526;
        background-color: #000;
        overflow: hidden; }

    // Group Categories
    #header-cat {
        overflow:hidden;
        min-height:53px;
        padding:0;
        border-right: 0px solid #232526;
        border-left: 0px !important;
        color:#6e6e6c;
        font-size:18px;
        font-weight:400;
        font-family: 'Montserrat', sans-serif;
        padding-bottom: 12px; }

    #header-cat div { padding:12px 0 0 21px !important; width:100%; }
    #header-cat ul { margin-left: 0px; list-style-type: none; }

    #header-cat ul .sub-active {
        color:red !important; /* #dfd538 */
        float: left;
        margin-right:14px; }

    #header-cat a:hover { color:#fff !important; }
    #header-cat a { color:#6e6e6c !important; }
    #header-cat span { color:#6e6e6c !important; }

    #hcat {
        width:100%;
        ul {
            padding-right: 10px;
        }
    }

    .cat-div { color:#232526 !important; padding: 0 0 0 14px; }

    .sub-link { float: left; margin-right:14px; color:#6e6e6c !important; }

    #rg-hcat ul .sub-active { color:#88c0ec !important; }
    #rg-hcat a:hover { color:#88c0ec !important; }

    @media only screen and (max-width:32.44em) {
        #dp-heading { font-size:28px !important; }

        #related-gear div { overflow:hidden; }

        #related-gear #rg-txt { width:0; }

        #slide-w-stripes img {
            float: none;
            padding: 0;
            padding-top: 10px;
            display: block;
            margin-left: auto;
            margin-right: auto;
        }

        #slide-w-x img {
            float: none;
            padding: 0;
            padding-top: 10px;
            display: block;
            margin-left: auto;
            margin-right: auto;
        }
        // #slide-w-x-rg { display:none; margin-top: 7px; margin-left: 10px; }  ?? NEEDED ??
    }

    #slide-g-wrap {
        position:relative;
        padding:0;
        margin-right:0px;
        overflow:visible;
        z-index: -1; }

    #slide-g {
        width:100%;
        background: none;
        overflow: visible; }

    #slide-g ul {
        /* background: rgba(39, 55, 68, 1) !important; */
        background-color: #273744; }

    #slide-g ul li a:hover { color:#fff !important; }

    .slide-spacer {}

    .catmenu {
        float:right;
        width:100%;
        padding: 7px 0 10px 7px !important;
        color:#999; }

    .rg-link { float: left; margin-right:14px; }

    .rg-link-active { color:#fff !important; font-weight: 700; }