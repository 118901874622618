//  ------------------------------------------------------
//  ------------------------------------------------------
//  DETAILS
//  ------------------------------------------------------
//  ------------------------------------------------------

// td-item
// relq-more-9 
// get-info

    .item-detail { overflow: hidden; margin-top: 14px; width: 100% !important; }

    .tab-text {
        text-align: center;
        line-height: 47px;
        white-space: nowrap;
        overflow: hidden;
        font-size:20px;
        font-weight:300;
        font-family: 'Montserrat', sans-serif;
        color:#6e6e6c; 
        padding: 0 11px 0 11px !important; 
    }

    .even .tab-text {
        border-left: 10px solid #191a1b;
    }

    .odd .tab-text {
        border-left: 10px solid #232526;
    }

    .detail-content { 
        padding-left: 14px;
        @include breakpoint(medium) {
            padding-left: 35px;
        }
        padding-right: 0;
        height:100%;
        overflow: auto;
    }

    .detail-content .empty { padding: 0 !important; height: 21px;   }

    .c-item { padding: 21px 0 10px 0; }

    .t-detail {
        padding-left: 14px;
        text-align: left;
        min-height: 27px;
        h4, h5, h6 {
            padding:0;
            margin:0;
            font-size: 17px;
        }
    }

    .rel-item { padding-top: 10px; padding-right: 0; width: 103%; margin-left: -14px; }

    .t-desc { padding-left:15px; }

    .t-img {
        float:right;
        overflow:hidden;
        height:27px;
        margin-right:14px;
        margin-left:7px;
    }

    .info-desc { display:none; }
    .info-descr { display:none; }

    //.info-descr div {
    //     margin:0;
    //}

    .info-desc .txt { /* color:#bbbcbc; */ padding-right: 14px; margin-left: -3px; }

    .relq { color:#fff; }
    .relq-more-9 {
        min-height:27px;
        padding-left:14px;
        padding-right:17px; }
    .relq-else {
        min-height:27px;
        padding-left:14px;
        padding-right:17px; }

    .l-item { padding-top: 10px; }

    .tab4-link { /* padding-right:-22px; */ }

    .highlight { color: #dcdc03 !important; }

    // EVEN/ODD ROW STYLES

    .even .detail-tabs { 
        /*margin-left: 61px;*/ 
        margin-left: 0px; 
        margin-right: 0px !important; 
        height:47px; 
        background-color:#191a1b; 
        white-space: nowrap;
    }

    .odd .detail-tabs {
        /*margin-left: 61px;*/
        margin-left: 0px;
        margin-right: 0px !important;
        height:47px;
        background-color:#232526;
        white-space: nowrap;
    }

    .even .tab-print {
        border-left: 2px solid #232526;
        padding: 10px 0 0 14px;
        cursor:pointer;
        color:#1460a2 !important;
        text-decoration:underline;
        height:100%;
    }

    .odd .tab-print {
        border-left: 2px solid #191a1b;
        padding: 10px 0 0 14px;
        cursor:pointer;
        color:#1460a2 !important;
        text-decoration:underline;
        height:100%;
    }

    .even .tab-print:hover { color:#C0C0C0 !important; }
    .even .tab-print:active { border-left: 3px solid red; margin-left: 14px; color:#fff !important; }

    .odd .tab-print:hover { color:#C0C0C0 !important; }
    .odd .tab-print:active { border-left: 3px solid red; margin-left: 14px; color:#fff !important; }

    .even .tab {
        margin-left: 14px;
        display:inline-block;
        height: 47px;
        background-color:#191a1b;
        border-bottom: 2px solid #191a1b;
        width: auto;
    }

    .odd .tab {
        margin-left: 14px;
        display:inline-block;
        height: 47px;
        background-color:#191a1b;
        border-bottom: 2px solid #191a1b;
        width: auto;
    }

    .even .tab:not(.tab-active):hover {
        background: #191a1b;
        cursor: pointer;
        text-decoration: none;
        border-bottom: 2px solid #FFF;
    }

    .odd .tab:not(.tab-active):hover {
        background: #232526;
        cursor: pointer;
        text-decoration: none;
        border-bottom: 2px solid #FFF;
    }

    .even .tab:not(.tab-active):hover .tab-text { color:#ebedf0 !important; }

    .odd .tab:not(.tab-active):hover .tab-text { color:#ebedf0 !important; }

    .even .tab-active {
        background-color:#232526;
        cursor: default;
        border-bottom: 2px solid red; }

    .odd .tab-active {
        background-color:#191a1b;
        cursor: default;
        border-bottom: 2px solid red; }

    .even .tab-active .tab-text { color:#ebedf0 !important; }

    .odd .tab-active .tab-text { color:#ebedf0 !important; }

    .even .d-item { border-bottom: 0px solid #191a1b; padding: 3px 7px 3px 0px; margin-left: 0px; width: 100%; }
    .even .info-active { background-color: #282a2b; color:red !important; }
    .even .td-item {
        border-bottom: 0px solid #191a1b;
        padding: 3px 0 7px 7px !important;
        @include breakpoint(medium) {
            padding: 3px 0 7px 14px !important;
        }
    }

    .odd .d-item { border-bottom: 0px solid #232526; padding: 3px 7px 3px 0px; margin-left: 0px; width: 100%; }
    .odd .info-active { background-color: #212223; color:red !important; }
    .odd .td-item {
        border-bottom: 0px solid #232526;
        padding: 3px 0 7px 7px !important;
        @include breakpoint(medium) {
            padding: 3px 0 7px 14px !important;
        }
    }

    .d-item .get-info { padding-right: 10px; }

    .even .t-item {
        border-bottom: 7px solid #191a1b; /* 303133 */
        padding: 7px 0 7px 0;
        width: 100%;
        /* background-color: #343c44; */ }

    .odd .t-item {
        border-bottom: 7px solid #232526;
        padding: 7px 0 7px 0;
        width: 100%;
        /* background-color: #343c44; */ }

    .even .t-item .t-detail { font-size: 16px; font-weight: 500; /*color:#e1e2e3;*/ color: #869fb4; }
    .even .t-item .t-relq { padding-left:28px !important; font-weight:700; }

    .odd .t-item .t-detail { font-size: 16px; font-weight: 500; /*color:#e1e2e3;*/ color: #869fb4; }
    .odd .t-item .t-relq { padding-left:28px !important; font-weight:700; }
    
    .even .st-item { border-bottom: 2px solid #191a1b; padding: 3px 0 3px 0px; margin-left: 0px; }
    .even .st-item .t-detail { font-size: 16px; font-weight: 500; color:#e1e2e3; }
    .even .st-item .t-relq { padding-left:18px !important; }

    .odd .st-item { border-bottom: 2px solid #232526; padding: 3px 0 3px 0px; margin-left: 0px; }
    .odd .st-item .t-detail { font-size: 16px; font-weight: 500; color:#e1e2e3; }
    .odd .st-item .t-relq { padding-left:18px !important; }

    .even .sst-item {
        border-top: 1px solid #191a1b;
        border-bottom: 1px solid #191a1b;
        font-style: italic;
        padding: 3px 0 3px 14px;
        margin-top: 10px; }

    .odd .sst-item {
        border-top: 1px solid #232526;
        border-bottom: 1px solid #232526;
        font-style: italic;
        padding: 3px 0 3px 14px;
        margin-top: 10px; }

    .even .sst-item-start {
        border-bottom: 1px solid #191a1b;
        font-style: italic;
        padding: 3px 0 3px 14px; }

    .odd .sst-item-start { border-bottom: 1px solid #232526; font-style: italic; padding: 3px 0 3px 14px; }

    .even .b-item {
        border-bottom: 1px solid #2a2c2d;
        border-top: 1px solid #2a2c2d;
        padding: 8px 0 8px 0;
        background-color:#232526; }

    .odd .b-item {
        border-bottom: 1px solid #232526;
        border-top: 1px solid #232526;
        padding: 8px 0 8px 0;
        background-color:#191a1b; }

    .even .e-item { border-bottom: 7px solid #191a1b; padding: 0px; }
    .even .et-item { border-bottom: 3px solid #191a1b; padding: 0px; margin-top: 10px; }
    .even .mline { border-right: 0; }
    .even .iline { border-left: 1px solid #2a2c2d; }
    .even .iline-invis { border-left: 1px solid #232526; }

    .odd .e-item { border-bottom: 7px solid #232526; padding: 0px; }
    .odd .et-item { border-bottom: 3px solid #232526; padding: 0px; margin-top: 10px; }
    .odd .mline { border-right: 0; }
    .odd .iline { border-left: 1px solid #2a2c2d; }
    .odd .iline-invis { border-left: 1px solid #191a1b; }

    .even .info {
        /*border-bottom: 1px solid #191a1b;*/
        padding: 10px 21px 10px 0px;
        @include breakpoint(medium) {
            padding: 10px 21px 10px 14px;
        }
        font-size: 14px; color:#e1e2e3; }

    .odd .info {
        /*border-bottom-: 1px solid #232526;*/
        padding: 10px 21px 10px 0px;
        @include breakpoint(medium) {
            padding: 10px 21px 10px 14px;
        }
        font-size: 14px;
        color:#e1e2e3; }
    
    .even .truck-info {
        /*border-bottom: 1px solid #191a1b;*/
        padding: 10px 21px 10px 32px;
        font-size: 14px; color:#e1e2e3; }

    .odd .truck-info {
        /*border-bottom-: 1px solid #232526;*/
        padding: 10px 21px 10px 32px;
        font-size: 14px;
        color:#e1e2e3; }

    .disable-select {
        /* -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none; */
    }