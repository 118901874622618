/*
    ------------------------------------------------------
    ------------------------------------------------------
    RESET
    ------------------------------------------------------
    ------------------------------------------------------
*/

/*  Global Defaults  */

html {
    // MUST BE 'auto' for scrollTop moves **** IMPORTANT *****
    height:auto !important;
    min-height:100%; }

body {
    // MUST BE 'auto' for scrollTop moves **** IMPORTANT *****
    height:auto !important;
    min-height:100%;
    background-color: $bg-color;
    background-image: $texcam-logo-lg-vertical;
    background-repeat:repeat;
    background-position: 1183px 0; }

.row {
    //background:#000;
    &.full-width {
    // Full-width overrides row settings
        /* background:#0c0c0c; */
        max-width:100% !important;
        // &.diff { /* background:#0c0c0c;*/ }
        .row {
            margin:0 auto;
            max-width:62.5rem;
            background:inherit;
        }
    }
}

#wrapper {
    z-index: 53;
    margin-left: 71px;
    height:100%;
    //background-image: $texcam-logo-lg-vertical;
    //background-repeat:repeat !important;
    //background-position: 1083px 0 !important;

    /* Firefox */
    width: -moz-calc(100% - 71px);
    /* WebKit */
    width: -webkit-calc(100% - 71px);
    /* Opera */
    width: -o-calc(100% - 71px);
    /* Standard */
    width: calc(100% - 71px);

  }


#wrapper-index { margin-top: 0px; }

#body-content {
    /* min-height:535px; */
    width:100%;
    max-width:1083px;
    overflow: hidden; // was, auto;
    background-color:#191a1b; /* 191a1b */
    color:#FFF; }

#dFrame { display:none; }

.none { display: none; }

.red { color:red; }

.white { color:#fff; }

.invis { visibility: hidden; }

.spacer { height: 14px; clear:both; }
.spacer-14 { height: 14px; clear:both; }
.spacer-35 { height: 35px; clear:both; }

.offscreen {
    position: absolute;
    clip: rect(1px 1px 1px 1px); /* for Internet Explorer */
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0;
    border: 0;
    height: 1px;
    width: 1px;
    overflow: hidden;
}

.print-options {
    max-height:53px;
    padding:0 0 12px 0;
    margin-top: -7px;
    text-align: right;
    overflow: visible;
    white-space : nowrap; /* this does the trick */
    /* border-left: 3px solid #232526; */ }

.print-options-detail {
    text-align: right;
    overflow: visible;
    white-space : nowrap; /* this does the trick */
    /* border-left: 3px solid #232526; */ }

.print-options .po-txt {
    margin-right: 0px;
}
    
.print-options-detail .po-txt {
    margin-right: 14px;
}

.po-txt {
    width:84px !important;
    float:right;
    text-align: right;
    overflow: visible;
}


.print-options .print-icon {
    margin-top: 3px;
    margin-right: -7px;
    display: inline-block;
    cursor: pointer;
}

.print-options .print-icon:hover { opacity: .53; }

.print-options-detail .print-icon {
    margin-top: 4px;
    margin-right: -7px;
    opacity: .53;
}
.print-options-detail .print-icon:hover {
    opacity: 1;
}

.print-options .download-icon {
    margin-top: 4px;
    margin-right: -32px;
    display: inline-block;
}
.print-options .download-icon:hover { opacity: .53; }

.print-options-detail .download-icon:hover {
    opacity: 1;
}
.print-options-detail .download-icon {
    opacity: .53;
    margin-top: 4px;
    margin-right: 0px;
    display: inline-block;
}

.contact-email { color:#ff000f; cursor: pointer;}