//  ------------------------------------------------------
//  ------------------------------------------------------
//  INDEX
//  ------------------------------------------------------
//  ------------------------------------------------------

//  Rotator, Categories & Additional Services (t-index.html)

.message .banner-msg {
    padding: 12px 12px;
}

.slick-dots {
    margin-left: -2px !important;
    margin-bottom: 14px !important;
    bottom: 0px !important;
    z-index:1 !important; }

.slick-dots li button { /* background: transparent; */ text-shadow: 0px 0px 3px #fff; }
.slick-dots li button:before { opacity: .53 !important; color: #000 !important; text-shadow: 0px 0px 3px #fff; }
.slick-dots li.slick-active button:before { /* opacity: .75; */ color:red !important; }
.mast-rotator { }

#index-logo {
    z-index:1 !important;
    width:100%;
    position: relative !important;
    height:0;
    overflow:visible;
    background-color: red; }

#index-logo div {
    margin-top: 7px;
    margin-right: 21px;
    height:0;
    width:287px;
    float:right; }

#index-rotator {
    z-index:997 !important;
    position: relative !important;
    margin-top: 0;
    height:474px;
    max-height: 474px;
    /* height:474px; */
    background-color: blue; /* #131313; */ }

#index-year-banner {
    z-index: 1 !important;
    position: relative !important;
    overflow: visible;
    margin-top: -42px;
    padding:0px;
    background: transparent !important; }

#masthead-r {
    position: relative !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    max-height: 470px;
    visibility: hidden;
    overflow:hidden; }

.mast-init { visibility: visible !important; }

#menu-search div { background-color: #000; width:100%; }

.search-wrap {
    margin-left: 0;
    padding-top: 21px;
    padding-bottom: 21px;
    width:100%;
    input[type="text"] {
        width:100%; font-size:17px; font-style: italic; color:red; border-left: 1px solid #000; margin-left:66px;
    }
}
.search-wrap input[type="text"]:focus { border-left: 1px solid #000; }

#index-cat { padding:0 0 0 0; width:100%; overflow: hidden; }
#index-cat img { opacity: 1; filter: alpha(opacity=100); /* border: 1px solid rgba(255, 0, 0, .5); */ }
#index-cat img:hover { opacity: 1; filter: alpha(opacity=100); /*border-left: 1px solid rgba(255, 0, 0, .5);*/ }
#index-cat .img-bg img { border-top: 1px solid #191a1b; border-bottom: 1px solid #191a1b; }
#index-cat .img-bg img:hover {
    border-top: 1px solid #191a1b;
    border-bottom: 1px solid #191a1b;
    /* border-top: 0px solid rgba(255, 255, 255, .5);
    border-bottom: 0px solid rgba(255, 255, 255, .5);*/ }

#index-catleft { overflow: visible; padding-right: 0px; }
#index-catright { overflow: visible; padding-left: 0px;	}

#index-services img { padding: 5px 0 0 0; }

#index-boxes { padding: 7px 0; width:100%; overflow: hidden; }
#index-boxes img { opacity: 0.7; filter: alpha(opacity=70); /* border: 1px solid rgba(255, 0, 0, .5); */ }
#index-boxes img:hover { opacity: 1; filter: alpha(opacity=100); /* border: 1px solid rgba(255, 0, 0, .5); */ }
#index-boxes div { overflow: visible; }
#index-boxes .img-bg img { border-top: 1px solid #191a1b; border-bottom: 1px solid #191a1b; }
#index-boxes .img-bg img:hover {
    border-top: 1px solid #191a1b;
    border-bottom: 1px solid #191a1b;
    /* border-top: 0px solid rgba(255, 255, 255, .5);
    border-bottom: 0px solid rgba(255, 255, 255, .5);*/ }

#index-btm-border {
    height:14px;
    background-color: #0a0b0b;
    border-bottom: 1px solid #2b2b2b;
    margin-bottom: 0px; clear:both; }