//  ------------------------------------------------------
//  ------------------------------------------------------
//  SLIDE MENU
//  ------------------------------------------------------
//  ------------------------------------------------------

    #slide-menu {
        color: $color-strip-menu-txt;
        text-align: center;
        font-size:12px;
        font-weight:400;
        font-family: 'Montserrat', sans-serif;
        cursor: pointer;
        padding-top:194px;
        padding-bottom: 14px;
        h1 { font-size: 13px; margin:0; padding:0; }
        h2 { font-size: 13px; margin:0; padding:0; }
    }

    #slide-x {
        display:none;
        padding-top:7px;
        margin-left:-3px; }

    #slide-m {
        width: 453px;
        z-index:99;
        background: rgba(12, 12, 12, .9) !important; }

    #slide-m ul {
        list-style: none;
        width: 353px; }

    #slide-m li a {
        padding-top: 21px;
        color: $color-slide-m-txt; }

    #slide-m .mtop:first-child {
        border-top: none; /* margin-top: -21px; */ }

    // Menu Level 1
    #slide-m .mtop {
        font-family: 'Montserrat', sans-serif;
        margin-top: 14px;
        padding-bottom: 0px;
        border-top: 1px solid $color-slide-m-divider;
        padding-top:14px; }

    #slide-m .mtop a {
        padding-top: 14px; }

    #slide-m .node-active {
        color:red !important;
    }

    #slide-m .active a {
        font-weight: 400;
        color: $color-slide-m-lvl1-txt-has-child; }

    // Menu Level 2
    #slide-m .submenu li a {
        font-style: italic;
        color: $color-slide-m-lvl1-txt;
        font-weight: normal; }

    #slide-m .active {
        color: $color-slide-m-txt-active; }

    // All Menu Levels
    #slide-m li a:hover {
        color: $color-slide-m-txt-hover; }

    #slide-m .sub-active {
        color: $color-slide-m-txt-sub-active; }

    #slide-m .entry-active {
        color: $color-slide-m-node-active; }

    .menu-box {
        margin-top:21px;
        margin-left:82px;
        text-align: left;
        font-weight:400;
        font-family: 'Montserrat', sans-serif;
        h4 { font-size:18px !important; margin:0; padding:0; }
        h5 { font-size:18px !important; margin:0; padding:0; }
    }