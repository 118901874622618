//  ------------------------------------------------------
//  ------------------------------------------------------
//  HEADER
//  ------------------------------------------------------
//  ------------------------------------------------------

    #header {
        background-image: url('/archive/images/headers/hbg-film-video.jpg');
        background-color:#191a1b;
        width:100%;
        max-width:1083px;
        height:240px; /* 240px */
        text-align: center !important;
        /* overflow-x: hidden;
        overflow-y: visible !important; */ }

    #header-404 {
        background-image: url('/archive/images/headers/hbg-film-video.jpg');
        /*background-color:#191a1b;*/
        width:100%;
        max-width:1083px;
        height:100%; /* 240px */
        /* overflow-x: hidden;
        overflow-y: visible !important; */ }

    #error-404 {
        width:70%;
        margin-left: auto;
        margin-right: auto;
        color:#fff;
        font-size: 53px;
    }

    .hbg-url-info { background-image: $header-bg-info; }

    .hbg-url-film-and-digital-cameras { background-image: $header-bg-film-and-digital-cameras; }
    .hbg-url-grip-and-electric { background-image: $header-bg-grip-and-electric; }
    .hbg-url-lenses { background-image: $header-bg-lenses; }
    .hbg-url-lights-and-lighting { background-image: $header-bg-lights-and-lighting; }
    .hbg-url-photography { background-image: $header-bg-photography; }

    .hbg-url-grip-trucks { background-image: $header-bg-grip-trucks; }
    .hbg-url-insert-stage { background-image: $header-bg-insert-stage; }

    .hbg-url-search { background-image: $header-bg-search; }

    #title-bar { padding: 14px; }

    #logo img { padding: 53px 10px 0px 10px; }

    #swrap {
        display: flex;
        justify-content: flex-end;
    }

    #search {
        display: flex;
        justify-content: flex-end;
        background-image: url('/_x/images/search-open-brighter.png');
        background-repeat:no-repeat;
        margin-top: 17px;
        width:316px;
        height:31px;
    }

    #search-glass {
        display:block;
        cursor: pointer;
        overflow: visible;
        width:77px;
        height:31px;
    }

    #search-input {
        margin: -2px 0 0 0;                       
    }

    #search-input #kw {
        margin-right: 21px;
    }

    input[type="text"] {
        /* background: rgba(38, 38, 38, 0.3); */
        background: transparent;
        border: none;
        outline: none;
        margin-top:4px;
        margin-left: -21px;
        width:244px;
        padding-left:7px;
        color:#bab9b9; /* #7e7d7d */
        height:27px;
        /* font-style: italic; */
        font-size: 15px; }

    input[type="text"]:focus {
        background: transparent;
        border: none;
        outline: none;
        color:red; }

    *:focus {
        outline: none; }

    .index-search div { background-color: #000 !important; padding: 0px; overflow: hidden; }

    .index-search input[type="text"] {
            line-height: 28px;
            width:100%;
            height:100%;
            font-size:17px;
            font-weight:600;
            font-style: italic;
            font-family: 'Montserrat', sans-serif;
            color:red;
            margin:0;
    }
    .index-search input[type="text"]::placeholder {
        color: red; /* Chrome, Firefox, Opera, Safari 10.1+ */
        opacity: 1; /* Firefox */
    }
    #mobile-glass input[type="text"] { padding: 26px 22px 24px 22px; }
    #desk-glass input[type="text"] { padding: 17px 22px; }
    #desk-glass .find { padding: 12px 22px; }
    .glass-wrap { background-color: #000 !important; }
    .glass { padding: 5px; height:100%; margin:0; margin-right:21px; }
    .glass-wrap:hover, .glass-wrap:focus { cursor: pointer; }