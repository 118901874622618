//  ------------------------------------------------------
//  ------------------------------------------------------
//  FOUNDATION OVERRIDES
//  ------------------------------------------------------
//  ------------------------------------------------------

@media only screen and (max-width:57em) and (orientation : landscape) {
    .is-stuck {
        position: relative !important;
        right: auto;
        left: auto;

        &.is-at-bottom {
        bottom: 0;
        }


    }
} /* min-width 1025px, large screens */