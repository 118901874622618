//  ------------------------------------------------------
//  ------------------------------------------------------
//  MAIN OVERRIDES
//  ------------------------------------------------------
//  ------------------------------------------------------

//  SLICK: node_modules/slick-carousel/slick/slick.scss
//  http://kenwheeler.github.io/slick/
//  https://developer.mozilla.org/en-US/docs/Web/CSS/cursor (Check To See If Another Cursor Override Value Would Be Better)
    .slick-list {
        &.dragging {
            cursor: pointer;
        }
    }

//  Break Points

    // TEST BREAKS [ small: 0, medium: 640px, large: 1024px, xlarge: 1200px, xxlarge: 1440px ]
    @include breakpoint(small only) {
        //body {
        //  display: none;
        //}
    }

//  Media Only

    /* SMALL -- */
    @media only screen and (max-width:32.44em) {
        /* index */
        #index-logo { display:none;  }

        /* Single Fluid Width Column 519px (Old Androids/iPhone3-3G-4-4S) */

        #mtop { z-index: 900; overflow: visible; height:35px; }

        .mtop { margin: -10px 0 0 -10px; }

        .top-bar-section li {
            margin-right:0px;
            margin-right: 7px;
            background: rgba(38, 43, 48, 1) !important;
            /* width:650px; */ }

        .top-bar-section .submenu li { background: rgba(38, 43, 48, 1) !important; }

        input[type="text"] { margin-left: -17px; }

        #main-menu { margin-left: -10px; }

        #slide-m { width: 95%; }

        #header-cat { border-right: 0px !important; font-size:18px; }

        #wrapper {
            margin-left: 53px;
            overflow-x: hidden;
            overflow: scroll;
            width:87%;
            /* Firefox */
            width: -moz-calc(100% - 53px);
            /* WebKit */
            width: -webkit-calc(100% - 53px);
            /* Opera */
            width: -o-calc(100% - 53px);
            /* Standard */
            width: calc(100% - 53px);
        }

        //#logo img { padding: 14px; padding-top: 53px; display:block; /* height:95px !important; */ }

        .top-bar { width:105%; }

        .it-detail-wrap { height:35px; z-index: 2; }

        .row-item { padding-left: 12px; }

        .it-title h3 { font-size:21px; }

        // duplicate due to Positioning
        .highlight { color: #dcdc03 !important; }

        .row-item-image { padding-left: 12px; }

        #search { background-image: url('/_x/images/search-open-brighter.png'); }

        .search-info { padding-left:10px !important; }

        input[type="text"] { background: rgba(12, 12, 12, 0.5); color:red; }

        input[type="text"]:focus { background: black; color:red; }

        #wrapper { margin-left: 53px; }

        #logo-strip { width:53px !important; margin-top: 0px; min-height:510px; background-position: -5px 0; /* background-size: 99%;*/ }
        #logo-strip-inner { width:53px !important; }

        #index-catleft { overflow: visible; padding-right: 0; }
        #index-catright { overflow: visible; padding-left: 0; }
        #index-cat { margin-bottom: 3px; }
        #index-btm-border { margin-bottom: 12px; clear:both; }

        #po-txt {
            /* width: 220px; */
            padding-right: 7px;
        }

        .it-rate { font-size:21px; text-align: right; }

        .get-detail { margin-left: 0px; }

        .open-detail .get-text {
            padding: 3px 14px;
            font-size: 17px;
            h4 { font-size:17px; }
        }

        .close-detail .get-text {
            padding-top: 0;
            font-size: 17px;
        }

        // YELLOW
        //.t-desc-wrap { background-color: yellow; }
        .t-desc-wrap { margin-left: 5px; }

        //.even .d-item { margin-left: 0px; padding-left: 0px; }
        //.odd .d-item { margin-left: 0px; padding-left: 0px; }

        //.even .t-img { float:right; }
        //.odd .t-img { float:right; }

        .even .st-item { padding-left: 5px; }
        .odd .st-item { padding-left: 5px; }

        .even .sst-item { padding-left: 5px; }
        .even .sst-item-start { padding-left: 5px; }
        .odd .sst-item { padding-left: 5px; }
        .odd .sst-item-start { padding-left: 5px; }

        //.even .td-item h6 { margin-left: 32px; }
        //.odd .td-item h6 { margin-left: 32px; }

        .even .info { padding-left: 17px; }
        .odd .info { padding-left: 17px; }

        .even .truck-info { padding-left: 17px; }
        .odd .truck-info { padding-left: 17px; }

        //.incl div { margin-left:10px !important; }

        .even .t-item { padding-left: 5px;
            h6 {
                //margin-left: -5px;
            }
        }
        .odd .t-item { padding-left: 5px;
            h6 {
                //margin-left: -5px;
            }
        }

        .tab-text {
            text-align: center;
            line-height: 47px;
            white-space: nowrap;
            overflow: hidden;
            font-size:17px;
            font-weight:300;
            padding: 0 21px 0 21px; }

        //.item-detail { overflow: visible; } //
        .even .tab { margin-left: -3px; } //
        .odd .tab { margin-left: -3px; } //

        .print-options-detail #po-txt {
            margin-right: 14px;
            overflow: visible;
        //    margin-top: 53px;
        //    height: 92px;
        }

        /* rental_view */
        #dept-header {
            border-right: 4px solid #232526;
            div {
                padding-top: 17px;
                padding-bottom: 16px;
                line-height: 90%;
            }
        }

        .row-html {
            padding-bottom:0;
            padding-left: 21px;
            padding-right: 21px;
            margin-bottom: -12px;
        }

        .download-print h3 { margin-left:0; }

        .download-print .indent { padding-left:14px; }

        .download-print .table-fwidth .c1 { width: 70%; }
        .download-print .table-fwidth .c2 { width: 15%; }
        .download-print .table-fwidth .c3 { width: 15%; }

        .how-to-reach { margin-left:0; padding:0; }

        #contact-map { margin: 21px 0 0 21px; }
        //#rg-heading { display: inline; line-height: 110% !important; }
    }

    // SMALL W/LANDSCAPE
    /* Single Fluid Width Column 519px (Old Androids/iPhone3-3G-4-4S) */
    @media only screen and (max-width:32.44em) and (orientation: landscape) {
        /* index */
        #index-logo { display:none;  }

        .it-rate { font-size:21px; text-align: right; }

        .get-detail { margin-left: 0px; }

        .even .st-item { padding-left: 5px; }
        .odd .st-item { padding-left: 5px; }

        //.incl div { margin-left:10px !important; }

        #slide-w-stripes img {
            float: none;
            padding: 0;
            padding-top: 10px;
            display: block;
            margin-left: auto;
            margin-right: auto;
        }

        #slide-w-x img {
            float: none;
            padding: 0;
            padding-top: 10px;
            display: block;
            margin-left: auto;
            margin-right: auto;
        }

        #rg-txt { display: none !important; }

        .t-desc-wrap { margin-left: 5px; }

        .get-text {
            padding: 3px 14px;
            font-size: 17px;
            h4 { font-size:17px; }
        }

        .row-html {
            padding-bottom:0;
            padding-left: 21px;
            padding-right: 21px;
            margin-bottom: -12px;
        }
        #contact-map { margin: 21px 0 0 21px; }
    }

    /* MEDIUM -- */
    /* Single Fixed Width Column 520px - 759px */
    @media only screen and (min-width:32.50em) and (max-width:47.44em) {
        /* index */
        #index-logo { display:none; }
        .top-bar { width:108%; }

        .search-wrap input[type="text"] { margin-left:75px; }
        .menu-box { margin-left:82px; }

        .slick-dots { margin-bottom: 12px !important; }

        #index-year-banner { margin-top: -5px; }

        /* .search-wrap div { padding:21px 21px 32px 21px; } */

        #index-cat { margin-top: -10px; }
        #wrapper {
            margin-left: 65px;
            /* Firefox */
            width: -moz-calc(100% - 65px);
            /* WebKit */
            width: -webkit-calc(100% - 65px);
            /* Opera */
            width: -o-calc(100% - 65px);
            /* Standard */
            width: calc(100% - 65px);
        }

        #logo-strip { width:65px !important; margin-top: 0px; min-height:510px; background-position: 0 0; /* background-size: 99%;*/ }
        #logo-strip-inner { width:65px !important; }

        .it-detail-wrap { height:53px; z-index: 2; }

        // BLUE
        //.t-desc-wrap { background-color: blue; }
        .t-desc-wrap { margin-left: 0; }


        .even .st-item { padding-left: 5px; }
        .odd .st-item { padding-left: 5px; }

        .it-rate { font-size:21px; text-align: right; }

        .get-detail { margin-left: 0px; }

        .get-text {
            padding: 3px 17px;
            font-size: 17px;
            h4 { font-size:17px; }
        }

        #slide-w-stripes img {
            float: none;
            padding: 0;
            padding-top: 14px;
            display: block;
            margin-left: auto;
            margin-right: auto;
        }

        #slide-w-x img {
            float: none;
            padding: 0;
            padding-top: 14px;
            display: block;
            margin-left: auto;
            margin-right: auto;
        }

        #rg-txt { display: none !important; }

        .row-html {
            padding-bottom:0;
            padding-left: 21px;
            padding-right: 21px;
            margin-bottom: -12px;
        }
        #contact-map { margin: 21px 0 0 21px; }
    }

    /* MEDIUM LARGE: iPAD -- */
    /* Two Fixed Width Columns, Medium Padding 760px - 959px (iPad) */
    @media only screen and (min-width:47.50em) and (max-width:59.94em) {
        /* index */
        #index-logo { display:none; }
        #logo-strip { background-position: 2px 0; }
        .top-bar { width:113%; }

        .search-wrap input[type="text"] { margin-left:76px; }

        .slick-dots { margin-bottom: 17px !important; }
        #index-year-banner { margin-top: -8px; }

        #index-cat { margin-top: -13px; }

        #index-services img { margin-bottom: -1px; }

        #index-boxes { padding-bottom: 6px; }

        .even .mline { border-right: 1px solid #2a2c2d; }
        .odd .mline { border-right: 1px solid #232526; }

        // GREEN
        //.t-desc-wrap { background-color: green; }
        .t-desc-wrap { margin-left: 0; }
    }

    @media only screen and (min-width:760px) and (max-width:953px) {
        #related-gear #rgmore { display: none !important; }
    }

    @media only screen and (min-width:760px) and (max-width:1097px) {
        #related-gear #rgr { display: none !important; }
    }

    @media only screen and (min-width:953px) and (max-width:1097px) {
        #related-gear #rgmore { display: initial; }
    }

    @media only screen and (min-width:760px) and (max-width:810px) {
        #related-gear #rg-txt { font-size:28px; margin-top: 22px; margin-left: 35px; margin-right: 65px !important; }
    }

    @media only screen and (min-width:810px) and (max-width:874px) {
        #related-gear #rg-txt { font-size:28px; margin-top: 22px; margin-left: 42px; margin-right: 65px !important; }
    }

    @media only screen and (min-width:874px) and (max-width:910px) {
        #related-gear #rg-txt { font-size:28px; margin-top: 22px; margin-left: 47px; margin-right: 65px !important; }
    }

    @media only screen and (min-width:910px) and (max-width:953px) {
        #related-gear #rg-txt { font-size:28px; margin-top: 22px; margin-left: 53px; margin-right: 65px !important; }
    }

    @media only screen and (min-width:953px) and (max-width:980px) {
        #related-gear #rg-txt { font-size:25px; margin-top: 24px; margin-left: 28px; margin-right: 65px !important; }
    }

    @media only screen and (min-width:980px) and (max-width:1035px) {
        #related-gear #rg-txt { font-size:25px; margin-top: 24px; margin-left: 28px; margin-right: 65px !important; }
    }

    @media only screen and (min-width:1035px) and (max-width:1053px) {
        #related-gear #rg-txt { font-size:25px; margin-top: 24px; margin-left: 32px; margin-right: 65px !important; }
    }

    @media only screen and (max-width:32.44em) {
        .callnowonly {
            font-size: 20px;
            text-align: center;
            font-weight: 600;
            color:#fff;
            margin-top: 0;
            display: block;
            margin-left: 14px;
        }
    }

    @media only screen and (min-width:1px) and (max-width:653px) {
        #related-gear-call #rg-txt-call { padding:0;  }
        .callnow { display: none; }
        .callnow-phone { display: none; }
        .callnowonly {
            font-size: 20px;
            text-align: center;
            font-weight: 600;
            color:#fff;
            margin-top: 14px;
            line-height: 120%;
            display: block;
        }
        .callnowonly a { color: #fff; width:77px; }
    }

    @media only screen and (min-width:32.50em) and (max-width:47.44em) {
        .callnowonly {
            margin-top: 19px;
            margin-left: 21px;
        }
    }

    @media only screen and (min-width:654px) and (max-width:853px) {
        .callnow { display: none; }
        .callnow-phone { display: none; }
        .callnowonly {
            font-size: 20px;
            text-align: center;
            font-weight: 600;
            color:#fff;
            margin-top: 0;
            display: block;
            margin-left: 21px;
        }
        .callnowonly a { color: #fff; }
    }

    @media only screen and (min-width:853px) and (max-width:910px) { .callnow-phone { margin: 2px 0 0 -3px; font-size:22px; font-weight:400; } }
    @media only screen and (min-width:910px) and (max-width:987px) { .callnow-phone { margin: 0px 0 0 -3px; font-size:24px; font-weight:400; } }
    @media only screen and (min-width:987px) and (max-width:1071px) { .callnow-phone { margin: -2px 0 0 -3px; font-size:26px; font-weight:400; } }

    @media only screen and (min-width:1053px) and (max-width:1097px) {
        #related-gear #rg-txt { font-size:25px; margin-top: 24px; margin-left: 35px; margin-right: 65px !important; }
    }

    @media only screen and (min-width:1037px) and (max-width:1097px) {
        #related-gear #rg-txt { font-size:25px; margin-top: 24px; margin-right: 65px !important; }
    }

    @media only screen and (min-width:1097px) and (max-width:1137px) {
        #related-gear #rg-txt { font-size:26px; margin-top: 24px; margin-right: 65px !important; }
    }

    /* LARGE OR LARGER -- DONE */
    /* Two Fixed Width Columns, Large Padding 960px (Desktop) */
    @media only screen and (min-width:60.00em) {
      /* index */
        #logo-strip { background-position: 2px 0; }

        #related-gear #rgr { display:inline; }

        .search-wrap input[type="text"] { margin-left:85px; }
        .menu-box { margin-left:92px; }

        #masthead-r { margin-top: 53px !important; margin-bottom: 0px; }
        .slick-dots { margin-bottom: 21px !important; }
        #index-year-banner { margin-top: -10px; }

        #index-cat { margin-top: -17px; }
        #index-catleft { overflow: visible; padding-right: 7px; }
        .i-cameras { margin-bottom:14px; }
        #index-catright { overflow: visible; padding-left: 5px;	}
        .i-lenses { margin-bottom:12px; }
        .i-lights { margin:0 0 0 4px; }

        .even .mline { border-right: 1px solid #2a2c2d; }
        .odd .mline { border-right: 1px solid #232526; }

        //.incl div { margin-left:10px !important; }

        #index-services img { margin: -10px 0 10px 0; }
        #index-services-middle img { margin-left:3px; }
        #index-services-right img { margin-left:4px; }

        #index-boxes { padding: 0 0 10px 0; }
    }

//  6. Slide Menu: Slidebar Plug-In Overrides // OLD TOP MENU ?????
//  ------------------------------------------------------

/*    p {
        text-align: left; }

    /* Giving a background-color to the nav container. */
/*    nav {
        margin: 0px 0 0 14px;
        position:absolute;
        width:1283px;
        /*background: transparent !important;
        background: rgba(0, 0, 0, .7) !important;*/
/*    }

    /* Since we'll have the "ul li" "float:left", we need to add a clear after the container. */
/*    nav:after {
        content:"";
        display:table;
        clear:both; }

    /* Removing padding, margin and "list-style" from the "ul", and adding "position:relative" */
/*    nav ul {
        padding:0;
        margin:0;
        list-style: none;
        position: relative;
        /*border-top: 1px solid red;
        border-bottom: 1px solid red;*/
/*    }

    /* Background color change on Hover */
/*    nav a:hover {
        background: rgba(0, 0, 0, .7) !important; }

    /* First Tier Dropdown Hover Effect
    nav ul ul a:hover { background: rgba(38, 43, 48, .9) !important; }

    /* Hide Dropdowns by Default, and giving it a position of absolute */
/*    nav ul ul {
        display: none;
        position: absolute; /* has to be the same number as the "line-height" of "nav a" */
/*        top: 42px;
        background: transparent !important; }

    /* Display Dropdowns on Hover */
/*    nav ul li:hover > ul {
        display:inherit;
        /* border-bottom: 1px solid #D3D3D3; */
/*    }

    /* First Tier Dropdown */
/*    nav ul ul li {
        width:100%; float:none;
        display:list-item;
        position: relative;
        /*background: rgba(38, 43, 48, .9) !important;
        background: rgba(0, 0, 0, .7) !important; */
/*        background: transparent !important; }

    /* Second, Third and more Tiers: We move the 2nd and 3rd etc tier dropdowns to the left by the amount of the width of the first tier. */
/*    nav ul ul ul li {
        position: relative;
        top:-60px; /* has to be the same number as the "width" of "nav ul ul li" */
/*        left:60px; }

    /* Change ' +' in order to change the Dropdown symbol */
    /* li > a:after { content:  ' +';  } */
/*    li > a:only-child:after {
        content: ''; }

    nav ul ul li .active {
        /*border-left: 1px solid red;*/
/*    }

    /* active up state */
/*    .active {
        background: transparent !important;
        border-left: 1px solid red; }

    #slide-m .active {
        background: transparent !important;
        border-left: 0px solid red; }

    .-active a {
        border-bottom: 1px solid #D3D3D3; }

    /* Styling the links */
/*    nav a {
        margin-left: 0;
        display:block;
        padding:0 10px;
        color:#FFF;
        font-size:14px;
        line-height: 42px;
        width:240px;
        overflow: hidden;
        background-color: red-;
        text-decoration:none;
        text-align: left !important; }

    /* Positioning the navigation items inline */
/*    nav ul li {
        margin: 0px;
        border-width: 0;
        display:inline-block;
        float: center;
        width:240px;
        /*border-left: 1px solid #D3D3D3; */
/*    }

    #mtop {
        z-index: 1000;
        margin-top: 0;
        position: absolute;
        overflow: hidden;
        min-width:100%;
        max-width: 1087px; }

    #container {
        margin: 0 auto auto 57px;
        position:absolute;
        max-width: 1082px;
        overflow: visible;
        height:53px; } */